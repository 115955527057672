import classNames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import PayHeader from './PayHeader/PayHeader';

export default function Pay(props) {

  const me = useSelector(selectMe)

  return (
    <div className={classNames('page', 'flex')}>
      <PayHeader />
      <div
        className={classNames('pageContent', 'pageShadow')}
        style={{ backgroundImage: `url(${me.user?.background_image})`, backgroundColor: `${me.user?.background_color}` }}
      >
        Контент
      </div>
    </div>
  );
}
