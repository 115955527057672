import { profileAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_USER_CALENDAR = 'SET_USER_CALENDAR';
const CREATE_EVENT = 'CREATE_EVENT';
const EDIT_EVENT = 'EDIT_EVENT';
const DELETE_EVENT = 'DELETE_EVENT';

let initialState = {
  calendar: {},
  isFetching: false
};

export const userCalendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_CALENDAR:
      return {
        ...state,
        calendar: action.calendar,
      }
    case CREATE_EVENT:
      return {
        ...state,
        calendar: {
          events: [...state.calendar.events, action.event]
        },
      }
    case EDIT_EVENT:
      return {
        ...state,
        calendar: {
          events: mapObj(state.calendar?.events, action.eventId, 'id', action.newObj)
        },
      }
    case DELETE_EVENT:
      return {
        ...state,
        calendar: {
          events: state.calendar?.events.filter((el) => el.id !== action.eventId)
        },
      }
    default: return { ...state };
  }
}

const setCalendar = (calendar) => ({ type: SET_USER_CALENDAR, calendar });
const setEvent = (event) => ({ type: CREATE_EVENT, event });
const editEvent = (eventId, newObj) => ({ type: EDIT_EVENT, eventId, newObj });
const deleteEvent = (eventId) => ({ type: DELETE_EVENT, eventId });

export const getUserCalendar = (id) => async (dispatch) => {
  await profileAPI.getUserCalendar(id)
    .then(response => dispatch(setCalendar(response.data)))
    .catch(err => console.log(err))
}

export const createUserEvent = ({
  userId: userId,
  date: date,
  time: time,
  name: name,
  repeat: repeat,
  end_date: end_date
}) => async (dispatch) => {
  await profileAPI.createUserEvent({
    userId: userId,
    date: date,
    time: time,
    name: name,
    repeat: repeat,
    end_date: end_date
  })
    .then(response => dispatch(setEvent(response.data)))
    .catch(err => console.log(err))
}
export const editUserEvent = ({
  userId: userId,
  eventId: eventId,
  date: date,
  time: time,
  name: name,
  repeat: repeat,
  end_date: end_date
}) => async (dispatch) => {
  await profileAPI.editUserEvent({
    userId: userId,
    eventId: eventId,
    date: date,
    time: time,
    name: name,
    repeat: repeat,
    end_date: end_date
  })
    .then(response => dispatch(editEvent(eventId, response.data)))
    .catch(err => console.log(err))
}
export const deleteUserEvent = ({
  userId: userId,
  eventId: eventId,
}) => async (dispatch) => {
  await profileAPI.deleteUserEvent({
    userId: userId,
    eventId: eventId,
  })
    .then(() => dispatch(deleteEvent(eventId)))
    .catch(err => console.log(err))
}
