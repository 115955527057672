import React, { useEffect, useRef, useState } from 'react';
import styles from './emoji.module.css';
import classNames from 'classnames';
import EmojiIcon from '../../../../svgIcons/EmojiIcon';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { useOutsideClick } from '../../../hooks/useOutsideClick';

export default function Emoji(props) {
  const ref = useRef(null)
  const [openEmoji, setOpenEmoji] = useState(false)

  useOutsideClick(ref, setOpenEmoji)

  return (
    <div className={classNames('flex', styles.emojiWrap)}>
      <button
        onClick={() => setOpenEmoji(true)}
        type="button"
        className={classNames('flex', 'iconBtn')}
      >
        <EmojiIcon />
      </button>
      {
        openEmoji &&
        <div ref={ref} className={styles.emojiBox}>
          <Picker
            data={data}
            onEmojiSelect={e => props.emojiClick(e)}
            locale='ru'
            previewPosition='none'
            dynamicWidth={true}
            className={styles.hghg}
          />
        </div>
      }
    </div>
  );
}
