import { useSortable } from '@dnd-kit/sortable';
import classNames from 'classnames';
import React, { useState } from 'react';
import SubTask from './SubTask/SubTask';
import styles from './task.module.css';
import TaskBanner from './TaskBanner/TaskBanner';
import TaskBody from './TaskBody/TaskBody';
import TaskCard from './TaskCard/TaskCard';
import TaskFooter from './TaskFooter/TaskFooter';
import TaskHeader from './TaskHeader/TaskHeader';
import { CSS } from '@dnd-kit/utilities';
import { SortableContext } from '@dnd-kit/sortable';
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import TaskControls from './TaskHeader/TaskControls/TaskControls';
import { useDispatch, useSelector } from 'react-redux';
import { selectProjectInfo } from '../../../../../../../../redux/ProjectPage/projectItemSelector';
import { selectLabels } from '../../../../../../../../redux/BoardPage/сolumnSelector';

export default function Task(props) {

  const projectInfo = useSelector(selectProjectInfo);
  const allTagsList = useSelector(selectLabels);

  const dispatch = useDispatch();

  const [isOpenSub, setIsOpenSub] = useState(false);
  const navigate = useNavigate();
  const link = useLocation()

  const hadleOpenSub = () => {
    isOpenSub ? setIsOpenSub(false) : setIsOpenSub(true);
  }

  const [isOpenTaskCard, setIsOpenTaskCard] = useState(link.hash === `#task-${props.task.id}` || false);
  const [isTargetTask, setIsTargetTask] = useState(false);

  const handleClickTaskCard = (id) => {
    setIsOpenTaskCard(true)
    document.body.classList.add('modal-show');
    navigate(`#task-${id}`);
  }

  const handleClickClosePopup = () => {
    setIsOpenTaskCard(false);
    document.body.classList.remove('modal-show');
    navigate('');
  }

  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging
  } = useSortable({
    id: props.task.id,
    data: {
      type: "Task",
      task: props.task
    }
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    link.hash === `#task-${props.task.id}` ? setIsTargetTask(true) : setIsTargetTask(false)
  }, [link.hash])

  // if (isDragging) {
  //   return <div
  //     ref={setNodeRef}
  //     style={style}
  //     className={classNames(styles.taskWrap, styles.shadow)}
  //   >
  //   </div>
  // }

  const subtasksIds = useMemo(() => {
    return props.task?.subtasks?.map(item => item.id);
  }, [props.task?.subtasks])

  return (
    <>
      <div
        className={classNames(styles.taskWrap, isDragging && styles.shadow)}
        onMouseDown={e => {
          e.stopPropagation()
        }}
        onClick={() => handleClickTaskCard(props.task.id)}
        ref={setNodeRef}
        style={{ style }}
        {...attributes}
        {...listeners}
      >
        <div
          style={{ backgroundColor: props.col?.color }}
          className={
            classNames(
              'flex',
              'contentBlock',
              styles.task,
              (isTargetTask && !isOpenTaskCard) && styles.targetTask,
              (props.task.img && !props.task.labels?.length) && styles.paddingTop
            )
          }
        >
          {
            allTagsList?.length > 0 &&
            <TaskHeader
              tags={props.task.labels}
              taskId={props.task.id}
            />
          }
          <div
            className={styles.controls}
            onClick={(e) => e.stopPropagation()}
          >
            <TaskControls
              task={props.task}
              column={props.col.name}
              colId={props.col.id}
              project={projectInfo}
              boardId={props.boardId}
            />
          </div>
          {
            props.task.img &&
            <TaskBanner
              img={props.task.img}
            />
          }
          <TaskBody
            project={projectInfo}
            users={props.task.performers}
            name={props.task.name}
            taskId={props.task.id}
            paddingRight={allTagsList?.length === 0 && !props.task.img}
          />
          {
            (props.task.deadline || props.task.attachments?.length > 0 || props.task.comments?.length > 0 || props.task.subtasks?.length > 0) &&
            <TaskFooter
              date={props.task.deadline}
              procent={props.task.procent}
              files={props.task.attachments}
              comments={props.task.comments}
              hadleOpenSub={hadleOpenSub}
              subtasks={props.task.subtasks}
            />
          }
        </div>
        <SortableContext items={subtasksIds || []}>
          {
            (props.task?.subtasks?.length > 0 && isOpenSub) &&
            <div
              className={classNames('flex', styles.subList)}
              onClick={(e) => {
                e.stopPropagation()
              }}
            >
              {
                props.task?.subtasks.map(item =>
                  <div
                    key={item.id}
                    className={classNames(styles.subtask)}
                  >
                    <SubTask
                      taskId={props.task.id}
                      item={item}
                      color={props.col?.color}
                      task={props.task}
                    />
                  </div>
                )
              }
            </div>
          }
        </SortableContext>

      </div>
      {
        isOpenTaskCard &&
        <div
          onClick={e => e.stopPropagation()}
        >
          <TaskCard
            project={projectInfo}
            task={props.task}
            column={props.col.name}
            colId={props.col.id}
            handleClickClose={handleClickClosePopup}
            boardId={props.boardId}
          />
        </div>
      }
    </>
  );
}
