import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import AddIcon from '../../../svgIcons/AddIcon';
import CheckedIcon from '../../../svgIcons/CheckedIcon';
import DeleteIcon from '../../../svgIcons/DeleteIcon';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import styles from './statuslist.module.css';

export default function StatusList(props) {

  const refList = useRef(null)

  useOutsideClick(refList, props.handleClickClose)

  return (
    <ul className={classNames(
      'flex',
      'shadow',
      styles.statusList,
      props.bottomRight && styles.bottomRight,
      props.bottomLeft && styles.bottomLeft,
      props.commentAccess && styles.commentAccess,
      props.forColumn && styles.forColumn
    )} ref={refList}>
      {props.statusList.map((item, index) =>
        <li
          key={index}
          className={styles.statusItem}
          onClick={() => {
            props.statusClick(item.id);
            setTimeout(() => props.handleClickClose(false), 0);
            props.closeControls && props.closeControls(false)
          }}
        >
          {props.commentAccess && props.isCheck === item.id && <span><CheckedIcon /></span>} {item.name}
        </li>
      )}
      {
        props.forColumn &&
        <li className={classNames('flex', styles.delItem)}>
          <button
            onClick={props.handleDeleteStatus}
            className={classNames('flex', 'iconBtn', 'nowrapString')}
          >
            <DeleteIcon /> Удалить
          </button>
          <button
            onClick={props.handleClickOpenStatus}
            className={classNames('flex', 'iconBtn', 'nowrapString')}
          >
            <AddIcon /> Создать новый
          </button>
        </li>
      }
    </ul>
  );
}
