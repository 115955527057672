import React, { useState } from 'react';
import styles from './boardcontrolitem.module.css';
import classNames from 'classnames';

export default function BoardControlItem(props) {

  const [showSublist, setShowSublist] = useState(false)

  return (
    <div
      onMouseEnter={() => setShowSublist(true)}
      onMouseLeave={() => setShowSublist(false)}
      className={props.item.withList && styles.withList}
    >
      <button
        className={classNames('flex', 'iconBtn', 'nowrapString', props.item.name === 'Вложения' && styles.resizeSVG)}
        onClick={props.item.key
          ?
          () => {
            props.item.callBack(props.item.key);
            props.handleClickClose(false);
          }
          :
          () => {
            props.item.callBack();
            props.handleClickClose(false);
          }}
        style={props.item.console && { background: 'red' }}

      >
        {props.item.icon}
        {props.item.name}
      </button>

      {(showSublist && props.item.withList) &&
        <div
          onClick={e => {
            e.stopPropagation()
          }}
          className={classNames('shadow', 'iconBtn', styles.controls, styles.sublist)}
        >
          {
            props.item.list.map(el =>
              <button
                key={el.id}
                onClick={() => {
                  props.item.listItemCallback(el.id)
                }}
                onMouseUp={(e) => {
                  e.stopPropagation()
                }}
                style={{ 'backgroundColor': `${el.color}` }}
                className={classNames('tag', Boolean(el.color) && 'tagWhite', 'iconBtn', 'nowrapString', styles.tag)}
              >
                #{el.name}
              </button>
            )
          }
        </div>
      }
    </div>
  )
}
