import classNames from 'classnames';
import React, { useEffect, useRef, useState, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router-dom";
import { Routes, Route } from 'react-router-dom';
import { getProjectInfo } from '../../../../redux/ProjectPage/projectItemReducer';
import { selectProjectInfo } from '../../../../redux/ProjectPage/projectItemSelector';
import Board from './Board/Board';
// import ProjectContent from './ProjectContent/ProjectContent';
import ProjectHeader from './ProjectHeader/ProjectHeader';
import styles from './projects.module.css';
import { Helmet } from "react-helmet";
import { socket } from '../../../../API/socket';
import Preloader from '../../../common/Preloader/Preloader';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { selectMe } from '../../../../redux/authSelectors';
import { getColumnStatuses } from '../../../../redux/BoardPage/сolumnsReducer';

const ProjectContent = lazy(() => import('./ProjectContent/ProjectContent'));
// const ProjectHeader = lazy(() => import('./ProjectHeader/ProjectHeader'));

export default function Projects(props) {

  let { projectId } = useParams();

  const dispatch = useDispatch();
  const info = useSelector(selectProjectInfo);
  const me = useSelector(selectMe);

  useEffect(() => {
    dispatch(getProjectInfo(projectId));
    dispatch(getColumnStatuses(projectId));

  }, [dispatch, projectId])

  // DragScroll
  const refPage = useRef(null)

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setMouseDown(true);
    setStartX(e.pageX - refPage.current.offsetLeft)
    setScrollLeft(refPage.current.scrollLeft)

    document.body.style.cursor = "grabbing"
  }
  const handleMouseLeave = () => {
    setMouseDown(false);
    document.body.style.cursor = "default"
  }
  const handleMouseUp = () => {
    setMouseDown(false);
    document.body.style.cursor = "default"
  }
  const handleMouseMove = (e) => {
    if (!mouseDown) return;
    e.preventDefault();

    const x = e.pageX - refPage.current.offsetLeft;
    const walk = x - startX;

    refPage.current.scrollLeft = scrollLeft - walk;
  }

  return (
    <>
      <Helmet>
        <title>{`${info?.name} - Boom-track`}</title>
      </Helmet>
      <Suspense fallback={<Preloader />}>
        <div
          className={classNames('page', 'flex')}
        >
          <ProjectHeader
            projectId={projectId}
            title={info.name}
            width={props.width}
          />
          <div
            className={classNames('pageContent',
              'pageShadow',
              props.isBoard && styles.boardContent
            )}
            style={{ backgroundImage: `url(${me.user?.background_image})`, backgroundColor: `${me.user?.background_color}` }}
            ref={refPage}
            onMouseDown={(e) => props.isBoard && handleMouseDown(e)}
            onMouseLeave={(e) => props.isBoard && handleMouseLeave(e)}
            onMouseUp={(e) => props.isBoard && handleMouseUp(e)}
            onMouseMove={(e) => props.isBoard && handleMouseMove(e)}
          >
            <Routes>
              <Route path='' element={<ProjectContent
                projectId={projectId}
              />} />
              <Route
                path='board/:boardId?'
                element={

                  <Board
                    // dragMoveLeft={dragMoveLeft}
                    // dragMoveRight={dragMoveRight}
                    // leftCoord={leftCoord}
                    // widthParent={widthParent}
                    projectId={projectId}
                  />

                } />
            </Routes>
          </div>
        </div>
      </Suspense>
    </>
  );
}
